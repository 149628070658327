<template>
  <!-- Tabla con flexbox de productos y clientes -->
  <section class="vista-impresion">
    <div class="row justify-content-end">
      <div class="col mt-encabezado order-1 order-sm-0 pt-2">
        <div class="d-flex flex-column flex-lg-row">
          <div class="col-lg p-0">
            <div class="row flex-lg-column">
              <div class="col-6 col-md-4 col-lg encabezado">
                <p class="print-only">
                  <span class="bold">Transportista: </span>{{despachos[$route.params.id] ? despachos[$route.params.id].t_nom_per : ''}} {{despachos[$route.params.id] ? despachos[$route.params.id].t_ape_per : ''}}
                </p>
                <p class="no-print bold">
                  {{despachos[$route.params.id] ? despachos[$route.params.id].t_nom_per : ''}} {{despachos[$route.params.id] ? despachos[$route.params.id].t_ape_per : ''}}
                  <small class="small-block">Transportista</small>
                </p>
              </div>
              <div class="col-6 col-md-4 col-lg encabezado">
                <p class="print-only">
                  <span class="bold">Placa: </span>{{despachos[$route.params.id] ? despachos[$route.params.id].pla_veh : ''}}
                </p>
                <p class="no-print bold">
                  {{despachos[$route.params.id] ? despachos[$route.params.id].pla_veh : ''}}
                  <small class="small-block">Placa</small>
                </p>
              </div>
              <p class="d-none d-lg-block col encabezado bold">
                Clientes a despachar: {{Object.keys(sucursales).length}}
              </p>
            </div>
          </div>
          <div class="col-lg p-0">
            <div class="row flex-lg-column justify-content-start">
              <div :class="['col-6 col-md-4 col-lg sticky-0 encabezado', {'d-none':!(vigilanteSeleccionado && !Object.values(vigilanteSeleccionado).includes(undefined))}]">
                <p class="print-only">
                  <span class="bold">Vigilante: </span>{{vigilanteSeleccionado.nom_per}} {{vigilanteSeleccionado.ape_per}}
                </p>
                <p class="no-print bold">
                  {{vigilanteSeleccionado.nom_per}} {{vigilanteSeleccionado.ape_per}}
                  <small class="small-block">Vigilante</small>
                </p>
              </div>
              <div :class="['col-6 col-md-4 col-lg sticky-0 encabezado', {'d-none':!(montacarguistaSeleccionado && !Object.values(montacarguistaSeleccionado).includes(undefined))}]">
                <p class="print-only">
                  <span class="bold">Montacarguista: </span>{{montacarguistaSeleccionado.nom_per}} {{montacarguistaSeleccionado.ape_per}}
                </p>
                <p class="no-print bold">
                  {{montacarguistaSeleccionado.nom_per}} {{montacarguistaSeleccionado.ape_per}}
                  <small class="small-block">Montacarguista</small>
                </p>
              </div>
              <div :class="['col-6 col-md-4 col-lg sticky-0 encabezado', {'d-none':!(almacenistaSeleccionado && !Object.values(almacenistaSeleccionado).includes(undefined))}]">
                <p class="print-only">
                  <span class="bold">Almacenista: </span>{{almacenistaSeleccionado.nom_per}} {{almacenistaSeleccionado.ape_per}}
                </p>
                <p class="no-print bold">
                  {{almacenistaSeleccionado.nom_per}} {{almacenistaSeleccionado.ape_per}}
                  <small class="small-block">Almacenista</small>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="d-lg-none encabezado bold">
            Clientes a despachar: {{Object.keys(sucursales).length}}
          </p>
          <p class="encabezado">
            <span class="bold">
              Vendedores:
            </span>
            <span
              v-for="vendedor in vendedores"
              :key="vendedor.id"
              class="auto-coma"
            >
              {{vendedor.nom_per}} {{vendedor.ape_per}}
              <span class="bold">({{ clientesPorVendedor(vendedor.id) }})</span>
            </span>
          </p>
        </div>
      </div>
      <div class="col-auto">
        <p class="nota-titulo">Nota de entrega</p>
        <p>Fecha de salida: {{ fechaSalida ? formatearFecha(fechaSalida) : '' }}</p>
        <p>N° de despacho: {{ $route.params.id }}</p>
      </div>
    </div>
    <div
      v-if="mostrarFiltrosCategoria"
      class="col-12 col-md-auto mb-sm-2 mb-md-0 py-2 px-0 px-sm-2 text-center btn-checkgroup-contenedor no-print"
    >
      <span
        v-for="cat in categorias"
        :key="'cat-'+cat.id_cat"
      >
        <input
          v-model="categoriasAMostrar"
          :id="`cat-${cat.id_cat}-${$route.params.id}`"
          type="checkbox"
          name="checkbox-categoria"
          :value="cat.id_cat"
          @click="categoriasAMostrar.includes(cat.id_cat)
            && categoriasAMostrar.length === 1
            ? $event.preventDefault()
            : ''"
        >
        <label :for="`cat-${cat.id_cat}-${$route.params.id}`" class="btn-checkgroup">
          {{cat.nom_cat}}
        </label>
      </span>
    </div>
    <div v-if="estadosAFiltrar">
      <div
        v-for="(z,i) in datosPedidos"
        :key="`pedidoID-${i}`"
        class="mt-2 mb-4 page-break-after"
      >
        <TablaDeImpresion
          class="maxH-80 p-0"
          mostrar-encabezado
          :cate-mostrar="categoriasAMostrar"
          :categorias="Object.keys(categorias).length ? categorias : null "
          :datos-pedidos="Object.keys(z.pedidos).length ? z.pedidos : null "
          :mostrar-totales-presentacion="parseInt(i) === Object.keys(datosPedidos).length-1 ? true : false"
          :totales-por-presentacion="totalesPorPresentacion"
          :totales-por-presentacion-pedidos="totalesPorPresentacionPedidos[i]"
          :cargaPedido="Object.keys(cargaPedido).length ? cargaPedido[i] : null"
          :nuevos="nuevos"
        />
      </div>
    </div>
    <footer class="no-print footer-fixed">
      <mdb-btn
        color="primario"
        icon="print"
        class="px-3 py-2"
        title="Imprimir tabla actual"
        @click="imprimirTabla"
      >
        <span class="mr-2">Imprimir</span>
      </mdb-btn>
      <mdb-btn
        flat
        dark-waves
        icon="cog"
        @click="mostrarConfiguracion = !mostrarConfiguracion"
      >
        Configuración
      </mdb-btn>
      <transition
        name="fading-transition"
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
        :duration="500"
        @after-enter="$refs.inputPedidosPorPagina.focus()"
      >
        <div
          v-if="mostrarConfiguracion"
          class="col-12 col-xl-auto col-xl-custom"
        >
          <div class="row align-items-center justify-content-center">
            <!-- Estados -->
            <div class="col-12 col-sm-6 col-md my-2 mx-xl-0">
              <div class="dropdown-select">
                <span class="dropdown-title">
                  Imprimir por estado(s)
                </span>
                <div class="pt-2 dropdown-selected">
                  <p v-for="(estado, index) in estadosAFiltrar" :key="index" class="celda-checkbox chip">
                    <label class="checkbox">
                      <input type="checkbox" :value="estado" v-model="estadosAFiltrar">
                      <p class="mr-2">
                        {{estado}}
                      </p>
                      <span class="icono-checkbox"/>
                    </label>
                  </p>
                </div>
                <mdb-dropdown dropup end class="dropdown-container col">
                  <mdb-dropdown-toggle slot="toggle" flat class="p-0 btn-dropdown">
                    <span class="icono-select" />
                  </mdb-dropdown-toggle>
                  <mdb-dropdown-menu>
                    <mdb-dropdown-item v-for="(estado, index) in estados" :key="index" class="celda-checkbox justify-content-start">
                      <label class="checkbox">
                        <input type="checkbox" :value="estado.est_zon" v-model="estadosAFiltrar">
                        <span class="icono-checkbox"/>
                        <p class="ml-2">
                          {{estado.est_zon}}
                        </p>
                      </label>
                    </mdb-dropdown-item>
                    <div class="dropdown-divider"></div>
                    <mdb-dropdown-item class="p-0">
                      <mdb-btn
                        flat
                        dark-waves
                        icon="broom"
                        class="my-2 mx-0 py-1 px-2"
                        @click="restablecerFiltros()"
                      >
                        Restablecer filtro
                      </mdb-btn>
                    </mdb-dropdown-item>
                  </mdb-dropdown-menu>
                </mdb-dropdown>
              </div>
            </div>
            <!-- Vendedores -->
            <div class="col-12 col-sm-6 col-md my-2 mx-xl-0">
              <div class="dropdown-select">
                <span class="dropdown-title">
                  Imprimir por vendedor(es)
                </span>
                <div class="pt-2 dropdown-selected">
                  <p v-for="(vendedor, index) in vendedoresAFiltrar" :key="index" class="celda-checkbox chip">
                    <label class="checkbox">
                      <input type="checkbox" :value="vendedor" v-model="vendedoresAFiltrar">
                      <p class="mr-2">
                        {{vendedor.nom_per}} {{vendedor.ape_per}}
                      </p>
                      <span class="icono-checkbox"/>
                    </label>
                  </p>
                </div>
                <mdb-dropdown dropup end class="dropdown-container col">
                  <mdb-dropdown-toggle slot="toggle" flat class="p-0 btn-dropdown">
                    <span class="icono-select" />
                  </mdb-dropdown-toggle>
                  <mdb-dropdown-menu>
                    <mdb-dropdown-item v-for="(vendedor, index) in vendedoresTodos" :key="index" class="celda-checkbox justify-content-start">
                      <label class="checkbox">
                        <input type="checkbox" :value="vendedor" v-model="vendedoresAFiltrar">
                        <span class="icono-checkbox"/>
                        <p class="ml-2">
                          {{vendedor.nom_per}} {{vendedor.ape_per}}
                        </p>
                      </label>
                    </mdb-dropdown-item>
                    <div class="dropdown-divider"></div>
                    <mdb-dropdown-item class="p-0">
                      <mdb-btn
                        flat
                        dark-waves
                        icon="broom"
                        class="my-2 mx-0 py-1 px-2"
                        @click="restablecerFiltros()"
                      >
                        Restablecer filtro
                      </mdb-btn>
                    </mdb-dropdown-item>
                  </mdb-dropdown-menu>
                </mdb-dropdown>
              </div>
            </div>
            <!-- Hojas -->
            <div class="col-12 col-sm-6 col-md-5 my-2">
              <mdb-input
                v-model.number="base"
                ref="inputPedidosPorPagina"
                type="number"
                label="# Pedidos por hoja"
                class="my-0 text-right"
                :min="2"
                outline
                @keyup.enter.native="filtroEstados()"
              >
                <mdb-btn
                  color="secundario"
                  size="md"
                  group
                  slot="append"
                  icon="sync"
                  class="z-depth-0"
                  @click="filtroEstados()"
                >
                  Aplicar
                </mdb-btn>
              </mdb-input>
            </div>
          </div>
        </div>
      </transition>
    </footer>
  </section>
</template>

<script>
import { mdbBtn, mdbInput, mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle } from 'mdbvue'
import TablaDeImpresion from '@/components/TablaDeImpresion.vue'
import { convertirNumeroAFixed, formatearFecha } from '@/funciones/funciones.js'
import { apiPost } from '@/funciones/api.js'
export default {
  name: 'NotaDeEntrega',
  components: {
    mdbBtn,
    mdbInput,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    TablaDeImpresion
  },
  props: {
    btnPaginacion: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formatearFecha,
      categoriasAMostrar: ['1', '2', '3'],
      categorias: [],
      productos: [],
      dnop: [],
      datosPedidos: {},
      datosPedidosTodos: {},
      cargaPedido: {},
      totalesPorPresentacion: {},
      totalesPorPresentacionPedidos: {},
      presentaciones: [],
      mostrarFiltrosCategoria: true,
      mostrarEncabezado: true,
      mostrarConfiguracion: false,
      des: [],
      base: 8,
      vigilanteSeleccionado: {},
      montacarguistaSeleccionado: {},
      almacenistaSeleccionado: {},
      fechaSalida: '',
      vendedoresTodos: {},
      vendedores: {},
      sucursales: {},
      estadosAFiltrar: [],
      vendedoresAFiltrar: []
    }
  },
  computed: {
    despachos () {
      if (!this.des || !this.des.des) return []
      const despachos = {}
      this.des.des.forEach(c => {
        if (!despachos[c.id_des]) {
          despachos[c.id_des] = {
            id_des: c.id_des,
            pla_veh: c.pla_veh,
            a_nom_per: c.a_nom_per,
            a_ape_per: c.a_ape_per,
            m_nom_per: c.m_nom_per,
            m_ape_per: c.m_ape_per,
            v_nom_per: c.v_nom_per,
            v_ape_per: c.v_ape_per,
            t_nom_per: c.t_nom_per,
            t_ape_per: c.t_ape_per
          }
        }
      })
      return despachos
    },
    estados () {
      if (!this.des || !this.des.peddes) return []
      const estados = {}
      this.des.peddes.forEach(e => {
        if (!estados[e.est_zon]) {
          estados[e.est_zon] = {
            est_zon: e.est_zon
          }
        }
      })
      return estados
    },
    notasPorPedido () {
      if (!this.dnop.length) return {}
      const notasPorPedido = {}
      this.dnop.forEach((np) => {
        if (!notasPorPedido[np.id_ped_np]) {
          notasPorPedido[np.id_ped_np] = {}
        }
        if (!notasPorPedido[np.id_ped_np][np.id_pre_np]) {
          notasPorPedido[np.id_ped_np][np.id_pre_np] = {}
        }
        notasPorPedido[np.id_ped_np][np.id_pre_np].cantidad = np.can_np
        notasPorPedido[np.id_ped_np][np.id_pre_np].inventario = np.can_ni ? np.can_ni : 0
      })
      return notasPorPedido
    },
    nuevos () {
      const nuevos = {
        porPed: {},
        porSuc: {}
      }
      if (!this.des || !this.des.nuevos) return nuevos
      this.des.nuevos.forEach((n) => {
        nuevos.porPed[n.id_ped] = true
        nuevos.porSuc[n.id_suc_ped] = true
      })
      return nuevos
    }
  },
  watch: {
    base (value) {
      if (value < 2) {
        this.base = 2
      }
    },
    estadosAFiltrar () {
      this.filtroEstados()
    },
    vendedoresAFiltrar () {
      this.filtroEstados()
    }
  },
  methods: {
    async cargaInicial () {
      this.resultados = {}
      await apiPost({ s: 'notaEntregaMV' }, 'des=' + this.$route.params.id)
        .then(async (res) => {
          this.des = res.data
          await this.pre(res.data)
          this.dnop = res.data.dnop
          await res.data.peddes.forEach((p) => {
            if (!this.datosPedidosTodos[p.est_zon]) {
              this.datosPedidosTodos[p.est_zon] = {
                est_zon: p.est_zon
              }
              this.datosPedidosTodos[p.est_zon].pedidos = {}
            }
            this.datosPedidosTodos[p.est_zon].pedidos[p.id_ped] = p
            this.datosPedidosTodos[p.est_zon].pedidos[p.id_ped].notas = this.notasPorPedido[p.id_ped]
            if (!this.vendedoresTodos[p.id_per]) {
              this.vendedoresTodos[p.id_per] = {
                nom_per: p.nom_per,
                ape_per: p.ape_per,
                id: p.id_per
              }
            }
          })
          await this.filtroEstados()
          await res.data.des.forEach((p) => {
            this.fechaSalida = p.fec_des
            this.fechaRetorno = p.fec_ret_des
            this.vigilanteSeleccionado = {
              id_per: p.v_id_per,
              nom_per: p.v_nom_per,
              ape_per: p.v_ape_per
            }
            this.montacarguistaSeleccionado = {
              id_per: p.m_id_per,
              nom_per: p.m_nom_per,
              ape_per: p.m_ape_per
            }
            this.almacenistaSeleccionado = {
              id_per: p.a_id_per,
              nom_per: p.a_nom_per,
              ape_per: p.a_ape_per
            }
          })
          await this.cate(res.data)
        })
    },
    pre (data) {
      if (!data || !data.pre) return {}
      const presentaciones = {}
      data.pre.forEach((p) => {
        presentaciones[p.id_pre] = p
      })
      this.presentaciones = presentaciones
    },
    cate (data) {
      if (!data || !data.cat) return {}
      const categorias = {}
      const productos = {}
      data.cat.forEach((c) => {
        categorias[c.id_cat] = c
        categorias[c.id_cat].productos = {}
      })
      data.pro.forEach((p) => {
        productos[p.id_pro] = p
        productos[p.id_pro].presentaciones = {}
      })
      data.pre.forEach((p) => {
        if (this.totalesPorPresentacion[p.id_pre]) {
          productos[p.id_pro_pre].presentaciones[p.id_pre] = p
        }
      })
      for (const p in productos) {
        if (Object.entries(productos[p].presentaciones).length) {
          categorias[productos[p].id_cat_pro].productos[productos[p].id_pro] = productos[p]
        }
      }
      for (const c in categorias) {
        if (!Object.entries(categorias[c].productos).length) {
          delete categorias[c]
        }
      }
      this.categorias = categorias
      this.productos = productos
    },
    cargaPed () {
      const cargaPed = {}
      for (const zon in this.datosPedidos) {
        if (!cargaPed[zon]) {
          cargaPed[zon] = {}
        }
        for (const ped in this.datosPedidos[zon].pedidos) {
          if (!cargaPed[zon][ped]) {
            cargaPed[zon][ped] = {}
          }
          this.des.dsap.forEach((s) => {
            if (cargaPed[zon][`${this.datosPedidos[zon].pedidos[ped].est_zon}${s.id_ped_np}`] && !cargaPed[zon][`${this.datosPedidos[zon].pedidos[ped].est_zon}${s.id_ped_np}`][s.id_pre_np]) {
              cargaPed[zon][`${this.datosPedidos[zon].pedidos[ped].est_zon}${s.id_ped_np}`][s.id_pre_np] = {}
              cargaPed[zon][`${this.datosPedidos[zon].pedidos[ped].est_zon}${s.id_ped_np}`][s.id_pre_np].cantidad = {}
              cargaPed[zon][`${this.datosPedidos[zon].pedidos[ped].est_zon}${s.id_ped_np}`][s.id_pre_np].peso = {}
              cargaPed[zon][`${this.datosPedidos[zon].pedidos[ped].est_zon}${s.id_ped_np}`][s.id_pre_np].cantidad = s.can_sap
            }
          })
        }
      }
      this.cargaPedido = cargaPed
      this.calcularTotales()
    },
    calcularTotales () {
      const totales = {
        cantidad: 0,
        peso: 0
      }
      const totalesP = {}
      for (const zona in this.cargaPedido) {
        totalesP[zona] = {}
        for (const pedido in this.cargaPedido[zona]) {
          totalesP[zona][pedido] = {
            cantidad: 0,
            peso: 0
          }
          for (const presentacion in this.cargaPedido[zona][pedido]) {
            if (this.presentaciones[presentacion]) {
              if (!totales[presentacion]) {
                totales[presentacion] = {
                  cantidad: 0,
                  peso: 0
                }
              }
              this.cargaPedido[zona][pedido][presentacion].peso = Number((this.cargaPedido[zona][pedido][presentacion].cantidad * Number(this.presentaciones[presentacion].pesm_pre)).toFixed(2))
              const cantidad = Number(this.cargaPedido[zona][pedido][presentacion].cantidad)
              const peso = Number((cantidad * Number(this.presentaciones[presentacion].pesm_pre)).toFixed(2))
              totales[presentacion].cantidad += cantidad
              totales[presentacion].peso += peso
              totales.peso += peso
              totales.cantidad += cantidad
              totalesP[zona][pedido].cantidad += cantidad
              totalesP[zona][pedido].peso += peso
            }
          }
        }
      }
      this.totalesPorPresentacion = totales
      this.totalesPorPresentacionPedidos = totalesP
      this.cargando = false
    },
    convertirValorAFixed (numero) {
      return convertirNumeroAFixed(numero)
    },
    imprimirTabla () {
      window.print()
    },
    filtroEstados () {
      let datosPedidosFil = {}
      this.sucursales = {}
      this.vendedores = {}
      if (this.estadosAFiltrar.length) {
        this.des.peddes.forEach((p) => {
          if (this.estadosAFiltrar.includes(p.est_zon)) {
            if (!this.sucursales[p.id_suc]) {
              this.sucursales[p.id_suc] = {
                id_suc: p.id_suc
              }
            }
            if (!this.vendedores[p.id_per]) {
              this.vendedores[p.id_per] = {
                nom_per: p.nom_per,
                ape_per: p.ape_per
              }
            }
          }
        })
        for (const zon in this.datosPedidosTodos) {
          if (this.estadosAFiltrar.includes(this.datosPedidosTodos[zon].est_zon)) {
            if (!datosPedidosFil[zon]) {
              datosPedidosFil[zon] = {
                est_zon: this.datosPedidosTodos[zon].est_zon
              }
              datosPedidosFil[zon].pedidos = this.datosPedidosTodos[zon].pedidos
            }
          }
        }
        this.datosPedidos = datosPedidosFil
      }
      if (this.vendedoresAFiltrar.length) {
        datosPedidosFil = {}
        this.vendedores = {}
        this.sucursales = {}
        if (this.estadosAFiltrar.length) {
          for (const z in this.datosPedidos) {
            for (const p in this.datosPedidos[z].pedidos) {
              this.vendedoresAFiltrar.forEach(vende => {
                if (vende.id === this.datosPedidos[z].pedidos[p].id_per_v_suc) {
                  if (!this.vendedores[this.datosPedidos[z].pedidos[p].id_per_v_suc]) {
                    this.vendedores[this.datosPedidos[z].pedidos[p].id_per_v_suc] = {
                      nom_per: this.vendedoresTodos[this.datosPedidos[z].pedidos[p].id_per_v_suc].nom_per,
                      ape_per: this.vendedoresTodos[this.datosPedidos[z].pedidos[p].id_per_v_suc].ape_per
                    }
                  }
                  if (!this.sucursales[this.datosPedidos[z].pedidos[p].id_suc]) {
                    this.sucursales[this.datosPedidos[z].pedidos[p].id_suc] = {
                      id_suc: this.datosPedidos[z].pedidos[p].id_suc
                    }
                  }
                  if (!datosPedidosFil[`${z + this.datosPedidos[z].pedidos[p].id_per_v_suc}`]) {
                    datosPedidosFil[`${z + this.datosPedidos[z].pedidos[p].id_per_v_suc}`] = {
                      est_zon: this.datosPedidos[z].est_zon,
                      pedidos: {}
                    }
                  }
                  datosPedidosFil[`${z + this.datosPedidos[z].pedidos[p].id_per_v_suc}`].pedidos[p] = this.datosPedidos[z].pedidos[p]
                }
              })
            }
          }
        } else {
          this.des.peddes.forEach((p) => {
            this.vendedoresAFiltrar.forEach(vende => {
              if (vende.id === p.id_per_v_suc) {
                if (!this.sucursales[p.id_suc]) {
                  this.sucursales[p.id_suc] = {
                    id_suc: p.id_suc
                  }
                }
                if (!this.vendedores[p.id_per]) {
                  this.vendedores[p.id_per] = {
                    nom_per: p.nom_per,
                    ape_per: p.ape_per
                  }
                }
              }
            })
          })
          for (const z in this.datosPedidosTodos) {
            for (const p in this.datosPedidosTodos[z].pedidos) {
              this.vendedoresAFiltrar.forEach(vende => {
                if (vende.id === this.datosPedidosTodos[z].pedidos[p].id_per_v_suc) {
                  if (!datosPedidosFil[`${z + this.datosPedidosTodos[z].pedidos[p].id_per_v_suc}`]) {
                    datosPedidosFil[`${z + this.datosPedidosTodos[z].pedidos[p].id_per_v_suc}`] = {
                      est_zon: this.datosPedidosTodos[z].est_zon,
                      pedidos: {}
                    }
                  }
                  datosPedidosFil[`${z + this.datosPedidosTodos[z].pedidos[p].id_per_v_suc}`].pedidos[p] = this.datosPedidosTodos[z].pedidos[p]
                }
              })
            }
          }
        }
        this.datosPedidos = datosPedidosFil
      }
      if (!this.estadosAFiltrar.length && !this.vendedoresAFiltrar.length) {
        this.des.peddes.forEach((p) => {
          if (!this.sucursales[p.id_suc]) {
            this.sucursales[p.id_suc] = {
              id_suc: p.id_suc
            }
          }
        })
        this.vendedores = this.vendedoresTodos
        this.datosPedidos = this.datosPedidosTodos
      }
      this.paginacion()
    },
    restablecerFiltros () {
      this.estadosAFiltrar = []
      this.vendedoresAFiltrar = []
      this.filtroEstados()
    },
    async paginacion () {
      const datosPed = {}
      let i = -1
      for (const zon in this.datosPedidos) {
        for (const ped in this.datosPedidos[zon].pedidos) {
          if (!datosPed[`${i}`] || Object.keys(datosPed[`${i}`].pedidos).length === this.base) {
            i += 1
            datosPed[`${i}`] = {
              est_zon: this.datosPedidos[zon].est_zon,
              pedidos: {}
            }
          }
          if (!datosPed[`${i}`].pedidos[`${this.datosPedidos[zon].est_zon}${ped}`]) {
            datosPed[`${i}`].pedidos[`${this.datosPedidos[zon].est_zon}${ped}`] = this.datosPedidos[zon].pedidos[ped]
          }
        }
      }
      this.datosPedidos = datosPed
      await this.cargaPed()
    },
    clientesPorVendedor (idVen) {
      let cantCli = 0
      Object.values(this.datosPedidosTodos).forEach((dat) => {
        Object.values(dat.pedidos).forEach((ped) => {
          if (ped.id_per_v_suc === idVen) {
            cantCli++
          }
        })
      })
      return cantCli
    }
  },
  mounted () {
    this.cargaInicial()
  }
}
</script>

<style lang="scss" scoped>
.vista-impresion {padding: 2rem 1rem 0;}
@media print {.vista-impresion {padding: 1rem 1rem 0;}}

$tabla-separador: rgba(0, 0, 0, 0.05);

.nota-titulo {
  font-size: 2rem;
  font-weight: bolder;
  text-align: end;
}
// Encabezados
[class*="encabezado"] {
  color: #616161;
  font-size: .75rem;
  line-height: 1.3;
  text-transform: uppercase;
}

// Negritas de 600
.bold {font-weight: 600;}

// Footer de la tabla
footer {
  align-items: center;
  border-top: 1px solid $tabla-separador;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: .75rem 0;
  margin: auto;

  &.footer-fixed {
    bottom: 0;
    position: sticky;
    width: 100%;
    background: white;
    z-index: 3;
  }
}
// celda-checkbox
.celda-checkbox {
  align-items: center;
  display: flex;
  font-size: .9rem;
  justify-content: space-between;

  p {
    font-size: inherit;
    line-height: 1.3;
    margin-bottom: 0;
  }

  .checkbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    margin-left: .25rem;
    user-select: none;
    white-space: nowrap;
  }
  .checkbox input[type="checkbox"] {
    display: none;

    & + .icono-checkbox::before {
      @include agregar-icono('\f0c8', 400);
      color: $gris-tr-borde;
      font-size: 1.45rem;
      transition: color .5s ease;
    }
    &:checked + .icono-checkbox::before {
      @include agregar-icono('\f14a');
      color: $exitoso;
    }
  }

  &.chip {
    .checkbox {
      padding: 0 10px;
    }
    .checkbox .icono-checkbox::before {
      @include agregar-icono('\f00d');
      color: $gris-muted;
    }
  }
  & > .md-form {flex-grow: 2;}
}
// Dropup
.dropdown-select {
  border: 2px solid $gris-tr-borde;
  border-radius: 4px;
  display: flex;
  min-height: 40px;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
  width: 100%;

  .dropdown-title {
    background: white;
    color: $gris-muted;
    font-size: 13px;
    font-weight: 500;
    left: 25px;
    position: absolute;
    text-align: start;
    transition: transform 0.2s ease-out, color 0.2s ease-out;
    top: -10px;
  }

  .dropdown-container {
    min-width: 50px;
    width: 100%;

    .btn-dropdown {
      height: 100%;
      position: absolute;
      right: 5px;
      text-align: end;
      width: 100%;

      .icono-select::after {
        @include agregar-icono('\f0d7', 900);
        color: $gris-muted;
      }
    }
  }

  .dropdown-selected {
    display: flex;
    flex-wrap: wrap;

    .chip {
      border-color: transparent;
      border-radius: 16px;
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      font-weight: 500;
      height: 20px;
      margin: 5px;
      padding: 0;
    }
  }

  &:focus-within {
    border-color: $primario;

    .dropdown-title {
      color: $primario;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }
}

.page-break-after{
  page-break-after: always;
}
// Estableciendo tamaños fijos a la tabla
@media screen {
  .tabla-pedidos.maxH-80 {
    max-height: 80vh;
  }
}

.col-xl-custom {
  @media screen and (min-width: 1200px) {
    flex: none;
    max-width: 100%;
    width: 740px;
  }
}
@media screen {
  .mt-encabezado {
    margin-top: 1.5rem;
    @media screen and (min-width: 992px) {
    margin-top: auto;
    }
  }
}
</style>
